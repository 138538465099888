import React, { Fragment } from "react";
import styles from "./slideStyle.module.scss";
import { useSwiper } from "swiper/react";

export default function SlideNavigation(props: { icon?: boolean }) {
  const swiper = useSwiper();
  return (
    <Fragment>
      <div className={styles["swiper-button-prev"]} onClick={() => swiper.slidePrev()} style={{ zIndex: 999 }}>
        {props.icon ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M5.87,241.84a0,0,0,0,0,0,0l104.51-104a20,20,0,0,1,28.22,28.36L68.44,236H492a20,20,0,0,1,0,40H68.44l70.17,69.82a20,20,0,0,1-28.22,28.36L5.88,270.18v0A20,20,0,0,1,5.87,241.84Z" />
          </svg>
        ) : (
          ""
        )}
      </div>
      <div className={styles["swiper-button-next"]} onClick={() => swiper.slideNext()} style={{ zIndex: 999 }}>
        {props.icon ? (
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
          >
            <g>
              <path d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068 c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557 l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104 c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z" />
            </g>
          </svg>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
}
