/* eslint-disable jsx-a11y/alt-text */
import Link from "next/link";
import React from "react";
import { ISapoProduct } from "../../model/base";
import styles from "./postDetailComponent.module.scss";
interface SapoProductsProps {
  items: ISapoProduct[];
}
export default function SapoProducts(props: SapoProductsProps) {
  const { items } = props;
  return (
    items &&
    items.length > 0 && (
      <div className={styles["block-product-sapo"]}>
        <div className={styles["h3"]}>
          <span>Sản phẩm Sapo</span>
        </div>
        <div className={styles.content}>
          {items.map((item, index) => (
            <div key={index}  className={styles.item + " " + item.id }>
              <div className={styles.icon}>{item.logo}</div>
                <div className={styles.info}>
                  <div className={styles.title}>{item.title}</div>
                  <p>{item.description}</p>
                  <Link href={item.link}  target="_blank" rel="noreferrer">
                  Tìm hiểu thêm
                  </Link>
                </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
}
