/* eslint-disable react/jsx-no-target-blank */
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styles from "../../components/article/article.module.scss";
import StarRating from "../starRating/StarRating";
import { useRouter } from "next/router";

export default function VoteAndLike({ articleId, point, seoTitle }: { articleId?: number; point?: any; seoTitle?: string }) {
  const [rate, setRate] = useState<number>(point || 5);
  const [total, setTotal] = useState<number>(0);
  const [isRate, setIsRate] = useState<boolean>(false);

  const router = useRouter();

  const handleRate = async (rate: number) => {
    point = point === "undefined" || !point ? 0 : point;
    fetch(`/blog/api/rate_article?articleId=${articleId}&rate=${rate}`)
      .then((response) => response?.json())
      .then((data) => {
        setRate(data.point);
        setTotal(data.total);
        setIsRate(true);
      });
  };

  useEffect(() => {
    async function checkRated() {
      fetch(`/blog/api/check_rated?articleId=${articleId}`)
        .then((response) => response?.json())
        .then((data) => {
          setTotal(data.total);
          setIsRate(data.exists === 0 ? false : true);
        });
    }
    checkRated();
    setRate(point);
  }, [articleId, point]);
  return (
    <div className={styles["box-vote-and-like"]}>
      <div className={styles["social-box"]}>
        <div className={classNames(styles["bg-social"], styles["fb-share-button-cus"])}>
          <div
            className={classNames("fb-share-button")}
            data-href={process.env.NEXT_PUBLIC_BASE_URL + process.env.NEXT_PUBLIC_BASE_PATH + router.asPath}
            data-layout="button_count"
            data-size="small"
          >
            <a
              target="_blank"
              href={`https://www.facebook.com/sharer/sharer.php?u=${
                process.env.NEXT_PUBLIC_BASE_URL + process.env.NEXT_PUBLIC_BASE_PATH + router.asPath
              }`}
              className="fb-xfbml-parse-ignore"
            >
              Chia sẻ
            </a>
          </div>
          <div className={styles["twitter-share-button"]}>
            <a
              href={`https://twitter.com/intent/tweet?text=${seoTitle} ${
                process.env.NEXT_PUBLIC_BASE_URL + process.env.NEXT_PUBLIC_BASE_PATH + router.asPath
              }`}
              target="_blank"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                <path fill="none" d="M0 0h72v72H0z" />
                <path
                  className="icon"
                  fill="#fff"
                  d="M68.812 15.14c-2.348 1.04-4.87 1.744-7.52 2.06 2.704-1.62 4.78-4.186 5.757-7.243-2.53 1.5-5.33 2.592-8.314 3.176C56.35 10.59 52.948 9 49.182 9c-7.23 0-13.092 5.86-13.092 13.093 0 1.026.118 2.02.338 2.98C25.543 24.527 15.9 19.318 9.44 11.396c-1.125 1.936-1.77 4.184-1.77 6.58 0 4.543 2.312 8.552 5.824 10.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163 0 6.345 4.513 11.638 10.504 12.84-1.1.298-2.256.457-3.45.457-.845 0-1.666-.078-2.464-.23 1.667 5.2 6.5 8.985 12.23 9.09-4.482 3.51-10.13 5.605-16.26 5.605-1.055 0-2.096-.06-3.122-.184 5.794 3.717 12.676 5.882 20.067 5.882 24.083 0 37.25-19.95 37.25-37.25 0-.565-.013-1.133-.038-1.693 2.558-1.847 4.778-4.15 6.532-6.774z"
                />
              </svg>
              Tweet
            </a>
          </div>
        </div>
      </div>
      <div className={classNames(styles["kksr-align-right"], styles["kk-star-ratings"], "kksr-valign-bottom")}>
        <StarRating
          unit="float"
          handleOnClick={(e) => handleRate(e)}
          initialRating={total > 0 ? rate : 0}
          isReadOnly={isRate}
          activeColor={`${rate < 4 ? "#FF8C00" : "#ffd055"}`}
          hoverColor={`${rate < 4 ? "#FF8C00" : "#ffd055"}`}
          size={20}
        />
        <div className={styles["star-point"]}>
          <b>{rate}</b>/<b>5</b> (<b>{total}</b> vote)
        </div>
      </div>
    </div>
  );
}
