import React, { useEffect } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";
import Link from "next/link";
import { useRouter } from "next/router";
import { fixParams, getDataFromUrl } from "../../util/functionGlobal";

interface PaginationProps {
  page: number;
  listPage?: number;
  setPage: any;
  itemPerPage?: number;
  totalItem?: number;
}

const switchPage = (num: number) => {
  switch (num) {
    case 0:
      return 3;
    case 1:
      return 2;
    case 2:
      return 2;
    case 3:
      return 2;
    default:
      break;
  }
};

const PaginationCustom = (props: PaginationProps) => {
  const router = useRouter();

  useEffect(() => {
    if (!getDataFromUrl("page", router.asPath)) {
      setPage(1);
    }
  }, [router.asPath]);

  const query = getDataFromUrl("query", router.asPath);

  const { page, listPage, setPage, itemPerPage, totalItem } = props;

  const pages = listPage ? listPage : Math.ceil(totalItem / itemPerPage);
  const getArrPage = () => {
    const arrPage: number[] = [];
    for (
      let index = page - 2 > 0 ? (pages - page <= 3 ? page - switchPage(pages - page) : page - 2) : 0;
      index < (page + 2 < pages ? (page < 1 ? page + switchPage(page) : page + 3) : pages);
      index++
    ) {
      if (index + 1 <= pages && index != 0 && index != pages - 1) {
        arrPage.push(index);
      }
    }
    return arrPage;
  };

  const handleChangPage = (num: number) => {
    setPage(num);
  };

  const handleClickPre = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleClickNext = () => {
    if (page < pages) {
      setPage(page + 1);
    }
  };
  return (
    <div id="box-table" className={styles.page_nav}>
      {page > 1 && (
        <Link
          href={
            page !== 2
              ? `${fixParams(router.asPath, "page")}page=${page > 1 ? page - 1 : 1}`
              : query
              ? `${fixParams(router.asPath, "page")}page=${page > 1 ? page - 1 : 1}`
              : router.asPath.split("?")[0]
          }
          className={classnames(styles["page-numbers"], styles.prev)}
          aria-label="prev-page"
          onClick={handleClickPre}
        >
          <i className="fa fa-angle-left" aria-hidden="true"></i>
        </Link>
      )}
      <Link
        href={query ? `${fixParams(router.asPath, "page")}page=${1}` : router.asPath.split("?")[0]}
        onClick={() => handleChangPage(1)}
        className={classnames(styles["page-numbers"], {
          [styles.current]: page === 1,
        })}
      >
        1
      </Link>
      {page > 3 && pages > 6 ? <div className={classnames(styles["page-numbers"], styles.dots)}>...</div> : null}
      {getArrPage().map((p) => (
        <Link
          key={p}
          href={`${fixParams(router.asPath, "page")}page=${p + 1}`}
          onClick={() => handleChangPage(p + 1)}
          className={classnames(styles["page-numbers"], {
            [styles.current]: page === p + 1,
          })}
        >
          {p + 1}
        </Link>
      ))}
      {page < pages - 3 && pages > 6 ? <div className={classnames(styles["page-numbers"], styles.dots)}>...</div> : null}
      {pages !== 1 && totalItem > itemPerPage && (
        <Link
          href={`${fixParams(router.asPath, "page")}page=${pages}`}
          onClick={() => handleChangPage(pages)}
          className={classnames(styles["page-numbers"], {
            [styles.current]: page === pages,
          })}
        >
          {pages}
        </Link>
      )}
      {page < pages && (
        <Link
          href={`${fixParams(router.asPath, "page")}page=${page < pages ? page + 1 : pages}`}
          className={classnames(styles["page-numbers"], styles.next)}
          aria-label="next-page"
          onClick={handleClickNext}
        >
          <i className="fa fa-angle-right" aria-hidden="true"></i>
        </Link>
      )}
    </div>
  );
};
export default PaginationCustom;
