import classNames from "classnames";
import styles from "./index.module.scss";
import Image from "next/legacy/image";
import { useEffect, useRef } from "react";
const YoutubeFacebook = () => {
  const refYoutube = useRef<HTMLDivElement>();
  const refBoxYoutube = useRef<HTMLDivElement>();
  useEffect(() => {
    const youtubescript = document.createElement("script");
    youtubescript.src = "https://apis.google.com/js/platform.js";
    if (refYoutube && refBoxYoutube && refBoxYoutube.current.childNodes.length < 2) {
      refYoutube.current.parentNode.appendChild(youtubescript);
    }
  });
  return (
    <>
      <div className={styles["youtube-facebook"]}>
        <div className="container">
          <div className="row">
            <div className={classNames(styles["col-md-6"], "col-md-6")}>
              <div className={styles.item}>
                <div className={styles.heading}>Tìm chúng tôi trên Facebook</div>
                <div
                  className={classNames("fb-page", styles["fb-page-custom"])}
                  data-href="https://www.facebook.com/sapo.vn"
                  data-tabs=""
                  data-width="500"
                  data-height="200"
                  data-small-header="false"
                  data-adapt-container-width="true"
                  data-hide-cover="false"
                  data-show-facepile="true"
                >
                  <blockquote
                    cite="https://www.facebook.com/sapo.vn"
                    className="fb-xfbml-parse-ignore"
                  >
                    <a
                      target="_parent"
                      rel="noopener"
                      href="https://www.facebook.com/sapo.vn"
                    >
                      Sapo - Nền tảng quản lý và bán hàng đa kênh
                    </a>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className={classNames(styles["col-md-6"], "col-md-6")}>
              <div className={styles.item}>
                <div className={styles.heading}>Tìm chúng tôi trên Youtube</div>
                <div className={"image-container"}>
                  <Image
                    src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/hoi-dap/img-banner-youtube.jpg"}
                    alt="banner youtube"
                    layout="fill"
                    objectFit="cover"
                    className={"image"}
                    quality={100}
                  />
                </div>
                <div className={classNames(styles["button-youtube"])} ref={refBoxYoutube}>
                  <div
                    ref={refYoutube}
                    className="g-ytsubscribe"
                    data-channelid="UCXOMQd_gKgELyY_fhmPjI4w"
                    data-layout="full"
                    data-count="default"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YoutubeFacebook;
