import styles from "./index.module.scss";
import { ITAG } from "../../model/base";
import Image from "next/legacy/image";
import Link from "next/link";
interface TagProps {
  data: ITAG[];
}
const Tag = (props: TagProps) => {
  const { data } = props;
  return (
    data?.length > 0 && (
      <>
        <div className={styles["post-tags"]}>
          <div className={styles["tag-title"]}>
            <Image src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/hoi-dap/icon-tag.png"} alt="tag" width="14" height="14" />
            <span style={{ marginLeft: 5 }}>Tags:</span>
          </div>
          {data &&
            data.map((item, index) => (
              <Link key={index} href={`/tags/${item.alias}`} title={item.tag}>
                {item.tag}
              </Link>
            ))}
        </div>
      </>
    )
  );
};
export default Tag;
