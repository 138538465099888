import React from "react";
import styles from "./postDetailComponent.module.scss";
import Link from "next/link";
import { IBaseBox } from "../../model/base";
interface HotNewsProps {
  title: string;
  items: IBaseBox[];
}
export function HotNews(props: HotNewsProps) {
  const { title, items } = props;
  return (
    <div className={styles["block-topic"]}>
      <div className={styles["h3"]}>
        <Link
          className={styles["a-link"]}
          href={
            "https://www.sapo.vn/khuyen-mai-hot-tu-sapo.html?utm_campaign=cpn%3Ablog_ref-plm%3A&utm_source=blog&utm_medium=referral&utm_content=fm%3Akhuyen_mai_hot-km%3A-sz%3A&utm_term=&campaign=blog_reft-km"
          }
          title={title}
        >
          {title}
        </Link>
      </div>
      <div className={styles.content}>
        {items &&
          items.slice(0, 2).map((item, index) => (
            <div className={styles.item} key={index}>
              <p>
                <Link href={`/${item.alias}`} title={item.title}>
                  {item.title}
                </Link>
              </p>
            </div>
          ))}
      </div>
    </div>
  );
}
