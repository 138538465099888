import { useEffect, useState } from "react";
import styles from "../postDetailComponent/postDetailComponent.module.scss";
import classnames from "classnames";
import { useRouter } from 'next/router';
import { convertSlugs } from "../../util/functionGlobal";

interface TopProps {
  activeHeading: string;
  refProps: any;
}

const ScrollMenu = ({ activeHeading, refProps }: TopProps) => {
  const [headings, setHeadings] = useState([]);
  const router = useRouter();

  useEffect(() => {
    const updateHeadings = () => {
      let content = document.querySelector("#_content");
      const h2Elements = Array.from(content.querySelectorAll('h2'));
      const headingTexts = h2Elements.map((element) => element.textContent);
      setHeadings(headingTexts);
    };

    updateHeadings();

    const handleRouteChange = (url, { shallow }) => {
      updateHeadings();
      const hash = url.split('#')[1];
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }

      if (shallow) {
        router.events.off('routeChangeComplete', handleRouteChange);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);
  const handleClick = (event, hash) => {
    event.preventDefault();
    const element = document.getElementById(hash);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {headings.length > 0 &&(
        <div className={styles["scroll-menu-focus"]} ref={refProps}>
          <div className={styles["h3"]}>
            <span>Tổng quan bài viết</span>
          </div>
          <ul className={styles["ul-focus"]}>
            {headings.map((heading, index) => {
               const hash = convertSlugs(heading);
                return(
                  <li
                  key={index}
                  className={styles[heading === activeHeading ? "active" : ""]} onClick={(e) => handleClick(e, hash)}
                  data-scroll-to={hash}>
                    {heading}
                  </li>
                )
              })}
          </ul>
        </div>
      )}
    </>
  );
};

export default ScrollMenu;
