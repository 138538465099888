/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { BannerHeaderBreadcrumb } from "../bannerHeader/bannerHeader";
import { HotNews } from "../postDetailComponent/scrollRight";
import { IBaseBox, ICategory } from "../../model/base";
import styles from "./article.module.scss";
import { dataSapoProducts } from "../../model/dataGlobal";
import SapoProducts from "../postDetailComponent/SAPOProduct";
import VoteAndLike from "../postDetailComponent/voteAndLike";
import Tag from "../tag";
import { SlidePost } from "../slideComponent/slideStyle";
import YoutubeFacebook from "../YoutubeFacebook/index";
import { useWindowSize, getAdsWithCondition, findAdsByIds, convertSlugs } from "../../util/functionGlobal";
import moment from "moment";
import { BtnHome } from "../btnHomeAndScrollTop/btnHomeAndScrollTop";
import router from "next/router";
import ScrollMenu from "./sidebarScrollFocus";

const Article = ({
  article,
  articleNew,
  resultCoupon,
  articleRevelant,
  categories,
  articleInterested,
  listAds,
}: {
  article?: IBaseBox;
  categories: ICategory[];
  articleRevelant;
  articleInterested;
  articleNew;
  resultCoupon: IBaseBox[];
  listAds;
}) => {
  const [slidesPerView, setSlidesPerView] = useState<number>(4);
  const refContent = useRef<HTMLDivElement>();
  const refWrapperContent = useRef<HTMLDivElement>();
  const boxScrollRight = useRef<HTMLDivElement>();
  const boxScrollLeft = useRef<HTMLDivElement>();
  const boxScrollFocus = useRef<HTMLDivElement>();
  const { width } = useWindowSize();
  

  const [activeHeading, setActiveHeading] = useState('');

  const [dataTop, setDataTop] = useState<IBaseBox[]>([]);

  const [listAdsBannerTop, setListAdsBannerTop] = useState<any[]>([]);
  const [listAdsOther, setlistAdsOther] = useState<any[]>([]);
  const [listAdsBannerEbook, setListAdsBannerEbook] = useState<any[]>([]);
  const listIdAdsTop = [35, 28, 27, 26, 29, 37, 38, 31, 32, 30, 36, 34, 143, 153, 156, 158, 159, 160, 162, 165, 197, 198]; // trong cái banner top là bao gồm cả banner bên góc trái
  const listIdAdsOther = [16, 3, 144, 151, 166, 175, 176, 204]; //banner other là accs banner khác
  const listIdAdsEbook = [73, 76, 81, 83, 78, 79, 71, 77, 74, 72, 82, 75, 1, 84, 12, 80, 146, 147, 155, 164, 169]; //banner ebook là banner ở dưới cuối trang

  //local
  //const listIdAdsTop = [30, 31, 41, 42, 43]; // trong cái banner top là bao gồm cả banner bên góc trái
  //const listIdAdsOther = [32, 33, 34, 35, 36, 37, 38]; //banner other là accs banner khác
  //const listIdAdsEbook = []; //banner ebook là banner ở dưới cuối trang

  const genAllParent = () => {
    let cateGen: any[] = [];
    if (article.category_article_mapping?.length === 0) return [];
    article.category_article_mapping?.forEach((item) => {
      let loop: boolean = true;
      let presentCategory = categories?.find((itm) => itm.id === item.category_id);
      cateGen.push(presentCategory?.id);
      while (loop) {
        presentCategory = categories?.find((itm) => itm.id === presentCategory?.parent_id);
        if (presentCategory != null) {
          cateGen.push(presentCategory?.id);
        } else {
          loop = false;
        }
      }
    });
    return cateGen;
  };

  const genCategory = () => {
    let cateGen: any[] = [];
    let presentCategory = categories?.find((item) => item.id === article?.category_article_mapping[0]?.category_id);
    cateGen.push(presentCategory);
    let loop: boolean = true;
    while (loop) {
      presentCategory = categories?.find((item) => item.id === presentCategory?.parent_id);
      if (presentCategory != null) {
        cateGen.push(presentCategory);
      } else {
        loop = false;
      }
    }
    cateGen.push({
      title: "Blog",
      alias: "",
    });
    return cateGen;
  };

  useEffect(() => {
    if (typeof window !== undefined) {
      if (width < 480) {
        setSlidesPerView(1.25);
      } else if (width < 990) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(4);
      }
    }
  }, [width]);

  //scroll focus content h2 left
  useEffect(()=>{
    const handleScroll = () => {
      let content = document.querySelector("#_content");
      const h2Elements = Array.from(content.querySelectorAll('h2'));
      const scrollPosition = window.scrollY;
      let activeIndex = -1;
      for (let i = 0; i < h2Elements.length; i++) {
        const element = h2Elements[i];
        const elementOffsetTop = element.offsetTop;

        if (scrollPosition >= elementOffsetTop) {
          activeIndex = i;
        }
      }

      if (activeIndex !== -1) {
        const activeHeadingText = h2Elements[activeIndex].textContent;
        setActiveHeading(activeHeadingText);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  const checkHoidapCategory = genAllParent()?.includes(86);

  useEffect(() => {
    if (checkHoidapCategory && dataTop.length === 0) {
      fetch(`/blog/api/get_top?id=${article.id}`)
        .then((response) => response?.json())
        .then((data) => {
          setDataTop(data.data);
        });
    }
  }, [checkHoidapCategory]);

  const [isExitsBanner, setIsExitsBanner] = useState<number>(1);
  const [navBanner, setNavBanner] = useState<any>(null);
  useEffect(() => {
    fetch(`/blog/api/view_increase?articleId=${article?.id}`);
    if (article.ads) {
      setListAdsBannerTop(findAdsByIds(listAds, [article.ads]));
    } else {
      setListAdsBannerTop(
        getAdsWithCondition(
          article?.article_tags_mapping?.map((item) => item.id),
          article?.categories_ids,
          findAdsByIds(listAds, listIdAdsTop)
        )
      );
    }
    setListAdsBannerEbook(
      getAdsWithCondition(
        article?.article_tags_mapping?.map((item) => item.id),
        article?.categories_ids,
        findAdsByIds(listAds, listIdAdsEbook)
      )
    );
    setlistAdsOther(
      getAdsWithCondition(
        article?.article_tags_mapping?.map((item) => item.id),
        article?.categories_ids,
        findAdsByIds(listAds, listIdAdsOther)
      )
    );
    setIsExitsBanner(1);
    setNavBanner(null);
  }, [article, listAds]);

  useEffect(() => {
    if (isExitsBanner >= 5) {
      setIsExitsBanner(0);
    } else {
      if (isExitsBanner > 0) {
        if (document.querySelector<HTMLElement>(".nav-banner")) {
          setNavBanner(document.querySelector<HTMLElement>(".nav-banner"));
          setIsExitsBanner(0);
        } else {
          setIsExitsBanner(isExitsBanner + 1);
        }
      }
      if (isExitsBanner === 0 && refWrapperContent.current) {
        const offsetTop = refWrapperContent.current.offsetTop;
        window.addEventListener("load", () => handleScroll(offsetTop));
        window.addEventListener("scroll", () => handleScroll(offsetTop));
        return () => {
          window.removeEventListener("load", () => handleScroll(offsetTop));
          window.removeEventListener("scroll", () => handleScroll(offsetTop));
        };
      }
    }
  }, [isExitsBanner, article, listAds]);

  const handleScroll = (offsetTop) => {
    if (refContent.current) {
      const endScroll = offsetTop + refContent.current.clientHeight;
      const scroll = window.pageYOffset;

      if (width > 1250) {
        if (navBanner) {
          if (scroll > offsetTop && scroll < endScroll - window.innerHeight) {
            navBanner.style.display = "block";
            navBanner.style.position = "fixed";
            navBanner.style.bottom = "0px";
            navBanner.style.left = "0px";
          } else {
            navBanner.style.display = "none";
          }
        }
        if (boxScrollLeft?.current) {
          if (scroll > offsetTop && scroll < endScroll - boxScrollLeft.current.clientHeight - 50) {
            boxScrollLeft.current.style.position = "fixed";
            boxScrollLeft.current.style.top = "50px";
          } else if (scroll > offsetTop && scroll > endScroll - offsetTop - boxScrollLeft.current.clientHeight - 50) {
            boxScrollLeft.current.style.position = "absolute";
            boxScrollLeft.current.style.top = endScroll - offsetTop - boxScrollLeft.current.clientHeight + "px";
          } else {
            boxScrollLeft.current.style.position = "absolute";
            boxScrollLeft.current.style.top = "50px";
          }
        }
        if (scroll > offsetTop && scroll < endScroll - boxScrollRight.current.clientHeight - 50) {
          boxScrollRight.current.style.position = "fixed";
          boxScrollRight.current.style.top = "50px";
        } else if (scroll > offsetTop && scroll > endScroll - offsetTop - boxScrollRight.current.clientHeight - 50) {
          boxScrollRight.current.style.position = "absolute";
          boxScrollRight.current.style.top = endScroll - offsetTop - boxScrollRight.current.clientHeight + "px";
        } else {
          boxScrollRight.current.style.position = "absolute";
          boxScrollRight.current.style.top = "50px";
        }

        if (boxScrollFocus?.current) {
          if (scroll > offsetTop && scroll < endScroll - boxScrollFocus.current.clientHeight - 50) {
            boxScrollFocus.current.style.position = "fixed";
            boxScrollFocus.current.style.top = "50px";
          } else if (scroll > offsetTop && scroll > endScroll - offsetTop - boxScrollFocus.current.clientHeight - 50) {
            boxScrollFocus.current.style.position = "absolute";
            boxScrollFocus.current.style.top = endScroll - offsetTop - boxScrollFocus.current.clientHeight + "px";
          } else {
            boxScrollFocus.current.style.position = "absolute";
            boxScrollFocus.current.style.top = "50px";
          }
        }

      } else {
        if (navBanner) {
          navBanner.style.display = "none";
        }
      }
    }
  };

  useEffect(() => {
    if (!document.getElementsByClassName("table-of-content").length) {
      if (process.browser) {
        let show = true;
        let content = document.querySelector("#_content");
        let item = content.querySelectorAll("h2,h3");

        if (item.length && content) {
          // let TableOfContents = document.createElement("div");
          // TableOfContents.className = "table-of-content";
          // let title = document.createElement("div");
          // let ul = document.createElement("UL");

          // title.className = "title";
          // title.appendChild(document.createTextNode("Các nội dung chính "));
          // let btnAction = document.createElement("span");
          // var icon =
          //   '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="451.847px" height="451.847px" viewBox="0 0 451.847 451.847" style="enable-background:new 0 0 451.847 451.847;" xml:space="preserve"><g><path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/></g>';
          // btnAction.innerHTML = icon;

          // title.appendChild(btnAction);
          // TableOfContents.appendChild(title);

          const scrollTo = router.asPath.split("#")[1];
          let itemScroll = null;
          item.forEach((i) => {
            const id = convertSlugs(i.textContent);
            if (scrollTo && scrollTo === id) {
              itemScroll = i;
            }
            i.id = id;
            let li = document.createElement("LI");
            if (i.tagName.toUpperCase() === "H3") {
              li.style.paddingLeft = "20px";
            }
            let a = document.createElement("a");
            a.href = `#${id}`;

            a.appendChild(document.createTextNode(i.textContent));
            li.appendChild(a);
           // ul.appendChild(li);
          });

          // TableOfContents.appendChild(ul);
          // item[0].parentNode.insertBefore(TableOfContents, item[0]);

          // if (itemScroll) {
          //   itemScroll.scrollIntoView({ behavior: "smooth" });
          // }
          // let heightUL = ul.offsetHeight;
          // //ul.style.height = heightUL + "px";
          // btnAction.onclick = function () {
          //   show = !show;
          //   if (show) {
          //     btnAction.className = "show";
          //     ul.style.height = heightUL + "px";
          //   } else {
          //     btnAction.className = "hide";
          //     ul.style.height = 0 + "px";
          //   }
          // };
        }
      }
    }
  }, [process.browser, article]);

  const rendProduct = (products: string) => {
    if (products) {
      const pro = JSON.parse(products);
      if (pro.length) {
        const data = dataSapoProducts.filter(function (sapoP) {
          return pro.includes(sapoP.id);
        });
        return data;
      }
    }
    return [];
  };

  var preservedScriptAttributes = {
    type: true,
    src: true,
    nonce: true,
    noModule: true,
  };
  function DOMEval(node) {
    var doc = window.document;
    var i,
      val,
      script = doc.createElement("script");
    script.text = node.innerHTML;
    if (node) {
      for (i in preservedScriptAttributes) {
        val = node[i] || (node.getAttribute && node.getAttribute(i));
        if (val) {
          script.setAttribute(i, val);
        }
      }
    }
    doc.head.appendChild(script).parentNode.removeChild(script);
  }

  useEffect(() => {
    if (process.browser) {
      if (listAdsOther && listAdsOther.length) {
        listAdsOther.forEach((item, index) => {
          const ads = document.getElementById(`ads-other-${index}`);
          if (ads) {
            const scripts = ads.getElementsByTagName("script");
            if (scripts && scripts.length) {
              for (let index = 0; index < scripts.length; index++) {
                DOMEval(scripts[index]);
              }
            }
          }
        });
      }
    }
    return () => {
      document.onmouseleave = null;
      window.onscroll = null;
      document.getElementsByTagName("body")[0].classList.remove("sapo-modal-register-open");
    };
  }, [listAdsOther, article, process.browser]);

  useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: "232837080820948",
        cookie: true,
        xfbml: true,
        autoLogAppEvents: true,
        version: "v11.0",
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    if (process.browser && window.FB) {
      FB.XFBML.parse();
    }
  }, [process.browser]);

  return (
    <div id={styles["wrapper"]} className={classNames(styles["main-content"], styles["page-detail"])}>
      <div className="post-content">
        {listAdsBannerTop && listAdsBannerTop.length > 0 && !checkHoidapCategory ? (
          <div className="d-none d-lg-block" dangerouslySetInnerHTML={{ __html: listAdsBannerTop[0].content }} />
        ) : (
          ""
        )}
        {listAdsOther && listAdsOther.length > 0 && !checkHoidapCategory ? (
          <>
            {listAdsOther.map((item, index) => (
              <div key={index} dangerouslySetInnerHTML={{ __html: item.content }} id={`ads-other-${index}`} />
            ))}
          </>
        ) : (
          ""
        )}
        <BannerHeaderBreadcrumb
          breadcrumb={genCategory()}
          title={article?.title}
          modDate={moment(article?.modified_at).format("DD-MM-YYYY")}
          view={article?.article_view_counts?.count?.toString() || "0"}
          img={article?.image_src ? process.env.NEXT_PUBLIC_BASE_IMAGE_URL + `/articles/${article?.image_src}` : process.env.NEXT_PUBLIC_NO_IMAGE}
          createdBy={article.created_by}
        />

        <div className={styles["detail-content"]} ref={refWrapperContent}>
          <div className="container">

            <ScrollMenu refProps={boxScrollFocus} activeHeading={activeHeading}/>
            {/* {checkHoidapCategory && dataTop.length > 0 ? (
              <Top refProps={boxScrollLeft} data={dataTop} title="Bài viết được xem nhiều" />
            ) : (
              <Top refProps={boxScrollLeft} data={[]} title="Bài viết được xem nhiều" />
            )} */}
            
            
            
            <div className={classNames(styles["sidebar-scroll"], "d-none d-xl-block")} ref={boxScrollRight} id="box-question">
              {checkHoidapCategory ? (
                <></>
                // <div className={styles["box-question"]}>
                //   <SendQuestion />
                // </div>
              ) : (
                <>
                  {articleNew?.length > 0 && <HotNews title="Bài viết mới nhất" items={articleNew} />}
                  {resultCoupon?.length > 0 && <HotNews title="Khuyến mãi hot" items={resultCoupon} />}
                </>
              )}

              <SapoProducts items={rendProduct(article.products)} />
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10 col-12" ref={refContent}>
                <div id="_content" className={styles["box-content"]} dangerouslySetInnerHTML={{ __html: article?.content1 }} />
                <VoteAndLike articleId={article?.id} point={article?.article_rating_point?.point} seoTitle={article.title} />
              </div>
            </div>
            <Tag data={article?.article_tags_mapping} />
            {/* <CommentFacebook /> */}
            {/* {checkHoidapCategory && (
              <div className="d-xl-none d-block" style={{ marginTop: 10 }}>
                <SendQuestion />
              </div>
            )} */}

            {articleRevelant?.length > 0 && (
              <SlidePost data={articleRevelant} title="BÀI VIẾT LIÊN QUAN" className="related-post" slidesPerView={slidesPerView} loop={true} />
            )}
            {articleInterested?.length > 0 && (
              <SlidePost data={articleInterested} title="CÓ THỂ BẠN QUAN TÂM" className="care-post" slidesPerView={slidesPerView} loop={true} />
            )}
          </div>
        </div>
      </div>
      {listAdsBannerEbook && listAdsBannerEbook.length > 0 && !checkHoidapCategory ? (
        <div dangerouslySetInnerHTML={{ __html: listAdsBannerEbook[0].content }} />
      ) : (
        ""
      )}
      <YoutubeFacebook />
      <BtnHome />
    </div>
  );
};
export default Article;
