import React, { useRef } from "react";
import classNames from "classnames";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import styles from "./slideStyle.module.scss";
import { IBaseBox } from "../../model/base";
import Link from "next/link";
import moment from "moment";
import Image from "next/legacy/image";
import SlideNavigation from "./SlideNavigation";
interface SildeStyleOneProps {
  slides: IBaseBox[];
  maxHeight?: number;
  categories?: any;
}

export function SildeStyleOne(props: SildeStyleOneProps) {
  SwiperCore.use([Autoplay, Navigation]);
  const { slides, maxHeight, categories } = props;
  return slides ? (
    <Swiper
      autoHeight={true}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      className={styles["index-slide"]}
    >
      {slides.map((post, index) => {
        let presentCategory = categories?.find((item) => item.id === post?.category_article_mapping[0]?.category_id);
        return (
          <SwiperSlide key={index}>
            <div className={classNames(styles["item"], "swiper-slide")}>
              <div className={classNames(styles["post-item"])} style={{ maxHeight: maxHeight }}>
                <div className={styles["thumb"]}>
                  <Link href={`/${post.alias}`} className={styles["slide_item"]}>
                    <Image
                      src={
                        post?.image_src ? process.env.NEXT_PUBLIC_BASE_IMAGE_URL + `/articles/${post?.image_src}` : process.env.NEXT_PUBLIC_NO_IMAGE
                      }
                      alt={post?.title}
                      objectFit="cover"
                      layout="fill"
                      className="image"
                      quality={100}
                    />
                  </Link>
                </div>
                <div className={styles["post-info"]}>
                  <h3>
                    <Link href={`/${post.alias}`} title={post.title}>
                      {post.title}
                    </Link>
                  </h3>
                  <p className="d-flex align-items-center flex-wrap">
                    <span className="d-flex align-items-center">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="far"
                        data-icon="clock"
                        className="svg-inline--fa fa-clock fa-w-16"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
                        ></path>
                      </svg>
                      {post.published_at
                        ? moment(post.published_at).format("DD-MM-YYYY")
                        : post.created_at
                        ? moment(post.created_at).format("DD-MM-YYYY")
                        : ""}
                    </span>
                    <span className="d-flex align-items-center">
                      {presentCategory ? (
                        <>
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512.001 512.001"
                            xmlSpace="preserve"
                          >
                            <g>
                              <path
                                d="M448.916,118.259h-162.05c-6.578,0-13.003-2.701-17.44-7.292l-50.563-53.264c-12.154-12.115-28.783-18.443-45.625-18.346 H63.084C28.301,39.356,0,67.657,0,102.439v307.123c0,34.783,28.301,63.084,63.084,63.084h386.064h0.058 c34.764-0.154,62.949-28.59,62.794-63.277V181.342C512,146.559,483.699,118.259,448.916,118.259z M473.417,409.447 c0.058,13.504-10.88,24.558-24.307,24.616H63.084c-13.504,0-24.5-10.996-24.5-24.5V102.439c0-13.504,10.996-24.5,24.5-24.52 H173.74c0.212,0,0.424,0,0.637,0c6.443,0,12.694,2.566,16.899,6.733l50.293,53.013
                          c11.806,12.192,28.32,19.176,45.297,19.176 h162.05c13.504,0,24.5,10.996,24.5,24.5V409.447z"
                              ></path>
                            </g>
                          </svg>
                          {presentCategory?.alias?.includes("/") ? (
                            <a
                              href={`${process.env.NEXT_PUBLIC_BASE_URL + process.env.NEXT_PUBLIC_BASE_PATH}/${presentCategory?.alias}`}
                              title={presentCategory.title}
                            >
                              {presentCategory.title}
                            </a>
                          ) : (
                            <Link href={`/${presentCategory?.alias}`} title={presentCategory.title}>
                              <span>{presentCategory && presentCategory.title}</span>
                            </Link>
                          )}
                        </>
                      ) : (
                        <>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.33 320">
                            <g>
                              <path d="M234.67,96a64,64,0,1,0,64,64A64,64,0,0,0,234.67,96Z" />
                              <path d="M234.67,0C128,0,36.91,66.35,0,160c36.91,93.65,128,160,234.67,160s197.76-66.35,234.66-160C432.43,66.35,341.44,0,234.67,0Zm0,266.67A106.67,106.67,0,1,1,341.33,160,106.71,106.71,0,0,1,234.67,266.67Z" />
                            </g>
                          </svg>
                          <span>{post.article_view_counts?.count || 0} Lượt xem</span>
                        </>
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
      <SlideNavigation icon={false} />
    </Swiper>
  ) : (
    <></>
  );
}

interface SildeStyleTwoProps extends SildeStyleOneProps {
  isPagination?: boolean;
}

export function SildeStyleTwo(props: SildeStyleTwoProps) {
  SwiperCore.use([Autoplay, Navigation, Pagination]);
  const { slides, maxHeight, isPagination = true } = props;

  const pagination = {
    clickable: true,
    clickableClass: styles["swiper-pagination-custom"],
    bulletClass: styles["item-bullet"],
    bulletActiveClass: styles["item-bullet-active"],
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };
  return slides ? (
    <>
      <Swiper
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={isPagination ? pagination : null}
        className={styles["dowload-swiper"]}
      >
        {slides.map((slide, index) => {
          const imageUrl = slide?.image_src
            ? process.env.NEXT_PUBLIC_BASE_IMAGE_URL + `/articles/${slide?.image_src}`
            : process.env.NEXT_PUBLIC_NO_IMAGE;
          return (
            <SwiperSlide key={index}>
              <div className={styles["swiper-wrapper"]}>
                <Link href={`/${slide.alias}`}>
                  <div className={classNames(styles["swiper-slide"], styles["swiper-slide-2"])}>
                    <Image src={imageUrl} alt={slide?.title} objectFit="cover" layout="fill" quality={100} />
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          );
        })}
        <SlideNavigation icon={true} />
      </Swiper>
    </>
  ) : (
    <></>
  );
}

interface SlidePostProps {
  data: IBaseBox[];
  title: string;
  slidesPerView?: number;
  spaceBetween?: number;
  loop?: boolean;
  className?: string;
}
export const SlidePost = (props: SlidePostProps) => {
  const { data, title, slidesPerView, spaceBetween, loop, className } = props;
  return (
    <>
      <div className={classNames(styles["slide-post"], styles[className], "suggest-post")}>
        <div className={styles.title}>
          <div className={styles.heading}>
            <Image src={process.env.NEXT_PUBLIC_BASE_PATH + "/images/hoi-dap/icon-bai-viet-lien-quan.png"} width="25" height="24" alt={title} />
            <span>{title}</span>
          </div>
        </div>
        <div className="block-content">
          <div className="swpiper-lien-quan swiper-container">
            <div className={styles["swiper-wrapper"]}>
              <Swiper
                modules={[Navigation, Pagination]}
                loop={data?.length > slidesPerView ? true : false}
                slidesPerView={slidesPerView ? slidesPerView : 3}
                spaceBetween={spaceBetween ? spaceBetween : 30}
                slidesPerGroup={1}
                centeredSlides={false}
                slidesPerGroupSkip={1}
                grabCursor={true}
                keyboard={{
                  enabled: true,
                }}
                scrollbar={true}
                className={styles["index-slide"]}
                height={350}
              >
                {data &&
                  data.map((post, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="swiper-slide item">
                          <Link href={"/" + post.alias} className={styles["thumb-image"]}>
                            <Image
                              src={
                                post?.image_src
                                  ? process.env.NEXT_PUBLIC_BASE_IMAGE_URL + `/articles/${post?.image_src}`
                                  : process.env.NEXT_PUBLIC_NO_IMAGE
                              }
                              alt={post?.title}
                              objectFit="cover"
                              layout="fill"
                              className="image"
                              quality={100}
                            />
                            <div className={styles["view"]}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 469.33 320">
                                <g>
                                  <path d="M234.67,96a64,64,0,1,0,64,64A64,64,0,0,0,234.67,96Z"></path>
                                  <path d="M234.67,0C128,0,36.91,66.35,0,160c36.91,93.65,128,160,234.67,160s197.76-66.35,234.66-160C432.43,66.35,341.44,0,234.67,0Zm0,266.67A106.67,106.67,0,1,1,341.33,160,106.71,106.71,0,0,1,234.67,266.67Z"></path>
                                </g>
                              </svg>
                              <span style={{ marginLeft: 5 }}>{post?.article_view_counts?.count || 0} lượt xem</span>
                            </div>
                          </Link>
                          <div>
                            <Link href={`/${post.alias}`} className={styles["post-title"]} title={post.title}>
                              {post.title}
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                <SlideNavigation icon={true} />
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
