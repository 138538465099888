/* eslint-disable react-hooks/exhaustive-deps */
import { SildeStyleOne } from "../slideComponent/slideStyle";
import styles from "./category.module.scss";
import React, { useEffect, useState } from "react";
import NewestPosts from "./../newestPosts/newestPosts";
import { IBaseBox } from "./../../model/base";
import PaginationCustom from "../pagination";
import classNames from "classnames";
import { useRouter } from "next/router";
import { findAdsByIds, parse_query_string } from "../../util/functionGlobal";
import { IAds } from "./../../model/ads";
import { ICategory } from "../../model/base";
import Link from "next/link";

const Category = ({
  articleSlide,
  data,
  count,
  listAds,
  categories,
  idCate,
  cateName,
}: {
  data: IBaseBox[][];
  count: number;
  articleSlide: IBaseBox[];
  listAds: IAds[];
  categories: ICategory[];
  idCate: number;
  cateName?: string;
}) => {
  const router = useRouter();

  const newestPost: IBaseBox[][] = data;
  const [breadcrumb, setBreadcrumb] = useState<ICategory[]>([]);
  const genCategory = (id: number, arr: ICategory[]) => {
    const cate = categories.find((cate) => cate.id === id);
    if (cate) {
      arr.push(cate);
      if (cate.parent_id) {
        genCategory(cate.parent_id, arr);
      }
    }
  };

  const [page, setPage] = useState<number>(Number(parse_query_string(router.asPath, "page")));

  useEffect(() => {
    if (page !== Number(parse_query_string(router.asPath, "page"))) {
      setPage(Number(parse_query_string(router.asPath, "page")));
    }
  }, [page, router.asPath]);

  const rendBreadcrumb = () => {
    let breadcrumbBase: ICategory[] = [];
    genCategory(idCate, breadcrumbBase);
    return breadcrumbBase;
  };

  useEffect(() => {
    setBreadcrumb(rendBreadcrumb());
  }, [data]);

  const [ads, setAds] = useState<IAds>();
  const listIdAdsOther = [135];
  useEffect(() => {
    const arrAds = findAdsByIds(listAds, listIdAdsOther);
    let i = 0;
    let loopInterval = null;
    if (arrAds.length > 1) {
      setAds(arrAds[i]);
      loopInterval = setInterval(() => {
        if (i + 1 < arrAds.length) {
          i++;
          setAds(arrAds[i]);
        } else {
          i = 0;
          setAds(arrAds[i]);
        }
      }, 7000);
    } else {
      setAds(arrAds[0]);
    }
    return () => clearInterval(loopInterval);
  }, []);

  const itemPerPage = 15;
  return (
    <>
      <div id={styles.wrapper} className="main-content">
        <div className="container">
          {breadcrumb.length > 0 ? (
            <ul className={styles["breadcrumb"]}>
              <li>
                <Link href="/">Blog</Link>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 490.8 490.8"
                    xmlSpace="preserve"
                  >
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82  l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262  c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115  L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667  c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </span>
              </li>
              {breadcrumb.reverse().map((bc, index) => (
                <li key={index}>
                  <Link href={"/" + bc.alias} legacyBehavior>
                    {bc.id === idCate ? (
                      <a>
                        <h1>{bc.title}</h1>
                      </a>
                    ) : (
                      <a>{bc.title}</a>
                    )}
                  </Link>
                  {index + 1 < breadcrumb.length ? (
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        id="Capa_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 490.8 490.8"
                        xmlSpace="preserve"
                      >
                        <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82  l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262  c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                        <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115  L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667  c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                      </svg>
                    </span>
                  ) : (
                    ""
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <h1>{cateName}</h1>
          )}
          <div className="row">
            {articleSlide?.length > 0 && (
              <>
                <div className={classNames(styles["no-padding-es"], "col-12 col-lg-8")}>
                  <SildeStyleOne slides={articleSlide} />
                </div>
                <div className="col-lg-4 topic-banner d-none d-lg-block" dangerouslySetInnerHTML={{ __html: ads?.content }} />
              </>
            )}
            <div className={classNames(styles["newest-posts"], "col-12")}>
              <div className="row">
                {newestPost?.map((posts, index) => (
                  <React.Fragment key={index}>
                    <NewestPosts posts={posts} />
                  </React.Fragment>
                ))}
              </div>
            </div>
            {count > 15 && <PaginationCustom page={page} setPage={setPage} itemPerPage={itemPerPage} totalItem={count} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Category;
